import { Show } from 'solid-js';
import IconPhotoNumber from '~/assets/icons/photoNumber.svg';
import IconDefaultUnit from '~/assets/images/home/defaultUnit.svg';
import { previewMultiple } from '~/components/common/ImagePreview';

interface PhotoWallProps {
  data: {
    url: string;
    title: string;
  }[];
}

export const PhotoWall = (props: PhotoWallProps) => {
  const previewImages = (index: number) => {
    previewMultiple(
      props.data.map((item) => item.url),
      index
    );
  };

  return (
    <div class="relative flex w-full justify-between">
      <Show
        when={props.data.length !== 0}
        fallback={
          <div class="flex h-[400px] w-full items-center justify-center rounded-lg bg-[#E7ECF6]">
            <img src={IconDefaultUnit} class="h-modalSm" />
          </div>
        }>
        <>
          <div>
            <img
              onClick={() => previewImages(0)}
              class="h-[583px] w-[979px] cursor-pointer"
              src={props.data[0]?.url}
              alt={props.data[0]?.title}
            />
          </div>
          <Show when={props.data.length > 1}>
            <div class="flex flex-col justify-between">
              <div>
                <img
                  onClick={() => previewImages(1)}
                  class="h-[287px] w-[332px] cursor-pointer"
                  src={props.data[1]?.url}
                  alt={props.data[1]?.title}
                />
              </div>
              <Show when={props.data.length > 2}>
                <div>
                  <img
                    onClick={() => previewImages(2)}
                    class="h-[287px] w-[332px] cursor-pointer"
                    src={props.data[2]?.url}
                    alt={props.data[2]?.title}
                  />
                </div>
              </Show>
            </div>
          </Show>

          <div
            onClick={() => previewImages(0)}
            class="absolute bottom-2 right-2 flex cursor-pointer items-center  gap-1 rounded-lg bg-black/70 px-3 py-1 text-white">
            <img src={IconPhotoNumber} alt="photo number" />

            <span>{props.data?.length}</span>
          </div>
        </>
      </Show>
    </div>
  );
};
