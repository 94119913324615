import { onMount, createSignal, createEffect } from 'solid-js';
import { ZERO_RESULTS_DESC, MAP_ERROR_DESC, CENTER_POINT } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
interface IProps {
  address: string;
}

const GoogleMap = (props: IProps) => {
  const { t } = useLocalization();
  let mapRef: HTMLDivElement;
  const [error, setError] = createSignal<string | null>(null);

  onMount(() => {
    // Check if the Google Maps script is already loaded
    if (!window.google || !window.google.maps) {
      loadGoogleMapsScript().then(initializeMap);
    }
  });

  createEffect(() => {
    if (window.google && window.google.maps) {
      initializeMap();
    }
  });

  const loadGoogleMapsScript = (): Promise<void> => {
    return new Promise((resolve) => {
      const existingScript = document.getElementById('google-maps-script');
      if (existingScript) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB89updWgLCG03Q81M-bZm0ESgVpSyCZ0I&libraries=places`;
      script.async = true;
      script.onload = () => resolve();
      document.body.appendChild(script);
    });
  };

  const initializeMap = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: props.address }, (results: any, status: any) => {
      if (status === 'OK') {
        const googleMap = new window.google.maps.Map(mapRef, {
          center: results[0].geometry.location,
          zoom: 15,
        });
        new window.google.maps.Marker({
          position: results[0].geometry.location,
          map: googleMap,
          title: t(CENTER_POINT),
        });
        setError(null);
      } else {
        if (status === 'ZERO_RESULTS') {
          setError(t(ZERO_RESULTS_DESC));
        } else {
          setError(t(MAP_ERROR_DESC));
        }
      }
    });
  };

  return (
    <div class="relative size-full">
      <div ref={mapRef!} class="size-full" />
      {error() && <div class="absolute inset-x-0 top-0 bg-red-500 p-2 text-center text-white">{error()}</div>}
    </div>
  );
};

export default GoogleMap;
